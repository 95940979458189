import React, { ReactElement } from "react";

import { PageHeader } from "@buildresonance/resonance-lib-layout-components";
import { DOCS_DOMAIN_NAME } from "@lib/common/domainNames";
import { Container } from "@mui/material";
import { openInNewTabUsingWindow } from "@util/openInANewTabUsingWindow";

import { MyDemandHqsCard } from "./MyDemandHqsCard";

const MyDemandHqsParent = (): ReactElement => {
    return (
        <Container>
            <PageHeader
                title="My DemandHqs"
                infoProps={{
                    label: "What is a DemandHq?",
                    iconButtonProps: {
                        onClick: () =>
                            openInNewTabUsingWindow(
                                `https://${DOCS_DOMAIN_NAME}/concepts/demand-hqs/demand-hq-concepts`,
                            ),
                    },
                }}
            />
            <MyDemandHqsCard />
        </Container>
    );
};

export { MyDemandHqsParent };
