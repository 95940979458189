import React from "react";

import { MyDemandHqsParent } from "@views/MyDemandHqsParent";
import { NextPage } from "next";
import Head from "next/head";

const HomePage: NextPage = () => {
    return (
        <>
            <Head>
                <title>My DemandHqs | Resonance</title>
            </Head>
            <MyDemandHqsParent />
        </>
    );
};

export default HomePage;
