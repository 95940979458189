import React, { ReactElement, ReactNode } from "react";

import { NextLink } from "@buildresonance/resonance-lib-core-components";
import {
    CardDataHeader,
    ChipList,
} from "@buildresonance/resonance-lib-display-components";
import { DemandHqIcon } from "@buildresonance/resonance-lib-icons";
import { useListDemandHqsForMe } from "@hooks/graphql/queries/useListDemandHqsForMe";
import { DemandAdminRoute } from "@lib/common/DemandAdminRoute";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
    Box,
    Button,
    Typography,
    Skeleton,
    Card,
    CardContent,
    CardHeader,
    List,
    Avatar,
    IconButton,
    Tooltip,
    useTheme,
    Chip,
} from "@mui/material";
import range from "lodash.range";
import { useRouter } from "next/router";
import pluralize from "pluralize";

const MyDemandHqsCard = (): ReactElement => {
    const router = useRouter();

    const theme = useTheme();

    const { data, status } = useListDemandHqsForMe();

    const renderDemandHqs = (): ReactNode => {
        if (status === "error") {
            return (
                <Typography variant="h5">
                    There was an error while loading demandHqs
                </Typography>
            );
        }

        if (status === "idle" || status === "loading") {
            return range(0, 4).map((number) => (
                <Box key={number} paddingBottom={theme.spacing(3)}>
                    <Skeleton variant="rectangular" height="2rem" />
                </Box>
            ));
        }

        if (
            data?.demandHqIdentities?.nodes &&
            data?.demandHqIdentities?.nodes?.length > 0
        ) {
            return (
                <List>
                    {data.demandHqIdentities.nodes.map((demandHqIdentity) => (
                        <NextLink
                            key={demandHqIdentity.demandHq.demandHqId}
                            passHref
                            href={DemandAdminRoute.Products.build(
                                demandHqIdentity.demandHq.demandHqId,
                            )}
                        >
                            <CardDataHeader
                                avatar={
                                    <Avatar>
                                        <DemandHqIcon />
                                    </Avatar>
                                }
                                title={demandHqIdentity.demandHq.name}
                                subheader={
                                    demandHqIdentity.demandHq.description
                                }
                                dataComponent={
                                    <ChipList>
                                        <Chip
                                            label={
                                                demandHqIdentity.demandHq
                                                    .demandSubscriptionCodes &&
                                                demandHqIdentity.demandHq
                                                    .demandSubscriptionCodes
                                                    .length > 0
                                                    ? demandHqIdentity.demandHq
                                                          .demandSubscriptionCodes[0]
                                                    : "NOT SUBSCRIBED"
                                            }
                                        />
                                    </ChipList>
                                }
                            />
                        </NextLink>
                    ))}
                </List>
            );
        }

        return (
            <Box textAlign="center">
                <Typography variant="h4" gutterBottom>
                    No DemandHqs Found
                </Typography>
                <Button
                    variant="contained"
                    onClick={() =>
                        router.push(DemandAdminRoute.DemandHqCreate.build())
                    }
                >
                    Create One
                </Button>
            </Box>
        );
    };

    return (
        <Card>
            <CardHeader
                subheader={`${pluralize(
                    "DemandHq",
                    data?.demandHqIdentities?.nodes?.length ?? 0,
                    true,
                )}`}
                action={
                    <Tooltip title="Create DemandHq">
                        <IconButton
                            aria-label="Create DemandHq"
                            onClick={() =>
                                router.push(
                                    DemandAdminRoute.DemandHqCreate.build(),
                                )
                            }
                        >
                            <AddCircleIcon />
                        </IconButton>
                    </Tooltip>
                }
            />
            <CardContent>
                <Box>{renderDemandHqs()}</Box>
            </CardContent>
        </Card>
    );
};

export { MyDemandHqsCard };
